export interface PhoneCountryData {
    country: string;
    dialCode: string;
    countryCode: string;
    format?: string;
    preferred?: boolean;
}

export const PhoneCountryDataList: PhoneCountryData[] = [
    { country: "Afghanistan", dialCode: "+93", countryCode: "AF" },
    { country: "Åland Islands", dialCode: "+358", countryCode: "AX" },
    { country: "Albania", dialCode: "+355", countryCode: "AL" },
    { country: "Algeria", dialCode: "+213", countryCode: "DZ" },
    { country: "American Samoa", dialCode: "+1684", countryCode: "AS" },
    { country: "Andorra", dialCode: "+376", countryCode: "AD" },
    { country: "Angola", dialCode: "+244", countryCode: "AO" },
    { country: "Anguilla", dialCode: "+1264", countryCode: "AI" },
    { country: "Antarctica", dialCode: "+672", countryCode: "AQ" },
    { country: "Antigua & Barbuda", dialCode: "+1268", countryCode: "AG" },
    { country: "Argentina", dialCode: "+54", countryCode: "AR" },
    { country: "Armenia", dialCode: "+374", countryCode: "AM" },
    { country: "Aruba", dialCode: "+297", countryCode: "AW" },
    { country: "Australia", dialCode: "+61", format: "X XXXX XXXX", countryCode: "AU" },
    { country: "Austria", dialCode: "+43", countryCode: "AT" },
    { country: "Azerbaijan", dialCode: "+994", countryCode: "AZ" },
    { country: "Bahamas", dialCode: "+1242", countryCode: "BS" },
    { country: "Bahrain", dialCode: "+973", countryCode: "BH" },
    { country: "Bangladesh", dialCode: "+880", countryCode: "BD" },
    { country: "Barbados", dialCode: "+1246", countryCode: "BB" },
    { country: "Belarus", dialCode: "+375", countryCode: "BY" },
    { country: "Belgium", dialCode: "+32", countryCode: "BE" },
    { country: "Belize", dialCode: "+501", countryCode: "BZ" },
    { country: "Benin", dialCode: "+229", countryCode: "BJ" },
    { country: "Bermuda", dialCode: "+1441", countryCode: "BM" },
    { country: "Bhutan", dialCode: "+975", countryCode: "BT" },
    { country: "Bolivia", dialCode: "+591", countryCode: "BO" },
    { country: "Bonaire, Sint Eustatius & Saba", dialCode: "+599", countryCode: "BQ" },
    { country: "Bosnia & Herzegovina", dialCode: "+387", countryCode: "BA" },
    { country: "Botswana", dialCode: "+267", countryCode: "BW" },
    { country: "Brazil", dialCode: "+55", format: "(XX) X XXXX-XXXX", countryCode: "BR" },
    { country: "British Indian Ocean Territory", dialCode: "+246", countryCode: "IO" },
    { country: "British Virgin Islands", dialCode: "+1284", countryCode: "VG" },
    { country: "Brunei", dialCode: "+673", countryCode: "BN" },
    { country: "Bulgaria", dialCode: "+359", countryCode: "BG" },
    { country: "Burkina Faso", dialCode: "+226", countryCode: "BF" },
    { country: "Burundi", dialCode: "+257", countryCode: "BI" },
    { country: "Cambodia", dialCode: "+855", countryCode: "KH" },
    { country: "Cameroon", dialCode: "+237", countryCode: "CM" },
    { country: "Canada", dialCode: "+1", format: "(XXX) XXX-XXXX", countryCode: "CA" },
    { country: "Cape Verde", dialCode: "+238", countryCode: "CV" },
    { country: "Cayman Islands", dialCode: "+1345", countryCode: "KY" },
    { country: "Central African Republic", dialCode: "+236", countryCode: "CF" },
    { country: "Chad", dialCode: "+235", countryCode: "TD" },
    { country: "Chile", dialCode: "+56", countryCode: "CL" },
    { country: "China", dialCode: "+86", format: "XXX XXXX XXXX", countryCode: "CN" },
    { country: "Christmas Island", dialCode: "+61", countryCode: "CX" },
    { country: "Cocos (Keeling) Islands", dialCode: "+61", countryCode: "CC" },
    { country: "Colombia", dialCode: "+57", countryCode: "CO" },
    { country: "Comoros", dialCode: "+269", countryCode: "KM" },
    { country: "Congo", dialCode: "+242", countryCode: "CG" },
    { country: "Cook Islands", dialCode: "+682", countryCode: "CK" },
    { country: "Costa Rica", dialCode: "+506", countryCode: "CR" },
    { country: "Côte d'Ivoire", dialCode: "+225", countryCode: "CI" },
    { country: "Croatia", dialCode: "+385", countryCode: "HR" },
    { country: "Cuba", dialCode: "+53", countryCode: "CU" },
    { country: "Curaçao", dialCode: "+599", countryCode: "CW" },
    { country: "Cyprus", dialCode: "+357", countryCode: "CY" },
    { country: "Czechia", dialCode: "+420", countryCode: "CZ" },
    { country: "Democratic Republic of the Congo", dialCode: "+243", countryCode: "CD" },
    { country: "Denmark", dialCode: "+45", countryCode: "DK" },
    { country: "Djibouti", dialCode: "+253", countryCode: "DJ" },
    { country: "Dominica", dialCode: "+1767", countryCode: "DM" },
    { country: "Dominican Republic", dialCode: "+1849", countryCode: "DO" },
    { country: "Ecuador", dialCode: "+593", countryCode: "EC" },
    { country: "Egypt", dialCode: "+20", countryCode: "EG" },
    { country: "El Salvador", dialCode: "+503", countryCode: "SV" },
    { country: "Equatorial Guinea", dialCode: "+240", countryCode: "GQ" },
    { country: "Eritrea", dialCode: "+291", countryCode: "ER" },
    { country: "Estonia", dialCode: "+372", countryCode: "EE" },
    { country: "Eswatini", dialCode: "+268", countryCode: "SZ" },
    { country: "Ethiopia", dialCode: "+251", countryCode: "ET" },
    { country: "Falkland Islands (Islas Malvinas)", dialCode: "+500", countryCode: "FK" },
    { country: "Faroe Islands", dialCode: "+298", countryCode: "FO" },
    { country: "Fiji", dialCode: "+679", countryCode: "FJ" },
    { country: "Finland", dialCode: "+358", countryCode: "FI" },
    { country: "France", dialCode: "+33", format: "X XX XX XX XX", countryCode: "FR" },
    { country: "French Guiana", dialCode: "+594", countryCode: "GF" },
    { country: "French Polynesia", dialCode: "+689", countryCode: "PF" },
    { country: "Gabon", dialCode: "+241", countryCode: "GA" },
    { country: "Gambia", dialCode: "+220", countryCode: "GM" },
    { country: "Georgia", dialCode: "+995", countryCode: "GE" },
    { country: "Germany", dialCode: "+49", format: "XX XXXX XXXX", countryCode: "DE" },
    { country: "Ghana", dialCode: "+233", countryCode: "GH" },
    { country: "Gibraltar", dialCode: "+350", countryCode: "GI" },
    { country: "Greece", dialCode: "+30", countryCode: "GR" },
    { country: "Greenland", dialCode: "+299", countryCode: "GL" },
    { country: "Grenada", dialCode: "+1473", countryCode: "GD" },
    { country: "Guadeloupe", dialCode: "+590", countryCode: "GP" },
    { country: "Guam", dialCode: "+1", countryCode: "GU" },
    { country: "Guatemala", dialCode: "+502", countryCode: "GT" },
    { country: "Guernsey", dialCode: "+44", countryCode: "GG" },
    { country: "Guinea", dialCode: "+224", countryCode: "GN" },
    { country: "Guinea-Bissau", dialCode: "+245", countryCode: "GW" },
    { country: "Guyana", dialCode: "+595", countryCode: "GY" },
    { country: "Haiti", dialCode: "+509", countryCode: "HT" },
    { country: "Honduras", dialCode: "+504", countryCode: "HN" },
    { country: "Hong Kong", dialCode: "+852", countryCode: "HK" },
    { country: "Hungary", dialCode: "+36", countryCode: "HU" },
    { country: "Iceland", dialCode: "+354", countryCode: "IS" },
    { country: "India", dialCode: "+91", countryCode: "IN" },
    //{ country: "Indonesia", dialCode: "+62", countryCode: "ID" },
    { country: "Iran", dialCode: "+98", countryCode: "IR" },
    { country: "Iraq", dialCode: "+964", countryCode: "IQ" },
    { country: "Ireland", dialCode: "+353", countryCode: "IE" },
    { country: "Isle of Man", dialCode: "+44", countryCode: "IM" },
    { country: "Israel", dialCode: "+972", countryCode: "IL" },
    { country: "Italy", dialCode: "+39", format: "XXX XXXXXXX", countryCode: "IT" },
    { country: "Jamaica", dialCode: "+1876", countryCode: "JM" },
    { country: "Japan", dialCode: "+81", format: "XX-XXXX-XXXX", countryCode: "JP" },
    { country: "Jersey", dialCode: "+44", countryCode: "JE" },
    { country: "Jordan", dialCode: "+962", countryCode: "JO" },
    { country: "Kazakhstan", dialCode: "+77", countryCode: "KZ" },
    { country: "Kenya", dialCode: "+254", countryCode: "KE" },
    { country: "Kiribati", dialCode: "+686", countryCode: "KI" },
    { country: "Kosovo", dialCode: "+383", countryCode: "XK" },
    { country: "Kuwait", dialCode: "+965", countryCode: "KW" },
    { country: "Kyrgyzstan", dialCode: "+996", countryCode: "KG" },
    { country: "Laos", dialCode: "+856", countryCode: "LA" },
    { country: "Latvia", dialCode: "+371", countryCode: "LV" },
    { country: "Lebanon", dialCode: "+961", countryCode: "LB" },
    { country: "Lesotho", dialCode: "+266", countryCode: "LS" },
    { country: "Liberia", dialCode: "+231", countryCode: "LR" },
    { country: "Libya", dialCode: "+218", countryCode: "LY" },
    { country: "Liechtenstein", dialCode: "+423", countryCode: "LI" },
    { country: "Lithuania", dialCode: "+370", countryCode: "LT" },
    { country: "Luxembourg", dialCode: "+352", countryCode: "LU" },
    { country: "Macau", dialCode: "+853", countryCode: "MO" },
    { country: "North Macedonia", dialCode: "+389", countryCode: "MK" },
    { country: "Madagascar", dialCode: "+261", countryCode: "MG" },
    { country: "Malawi", dialCode: "+265", countryCode: "MW" },
    { country: "Malaysia", dialCode: "+60", countryCode: "MY" },
    { country: "Maldives", dialCode: "+960", countryCode: "MV" },
    { country: "Mali", dialCode: "+223", countryCode: "ML" },
    { country: "Malta", dialCode: "+356", countryCode: "MT" },
    { country: "Marshall Islands", dialCode: "+692", countryCode: "MH" },
    { country: "Martinique", dialCode: "+596", countryCode: "MQ" },
    { country: "Mauritania", dialCode: "+222", countryCode: "MR" },
    { country: "Mauritius", dialCode: "+230", countryCode: "MU" },
    { country: "Mayotte", dialCode: "+262", countryCode: "YT" },
    { country: "Mexico", dialCode: "+52", countryCode: "MX" },
    { country: "Micronesia", dialCode: "+691", countryCode: "FM" },
    { country: "Moldova", dialCode: "+373", countryCode: "MD" },
    { country: "Monaco", dialCode: "+377", countryCode: "MC" },
    { country: "Mongolia", dialCode: "+976", countryCode: "MN" },
    { country: "Montenegro", dialCode: "+382", countryCode: "ME" },
    { country: "Montserrat", dialCode: "+1664", countryCode: "MS" },
    { country: "Morocco", dialCode: "+212", countryCode: "MA" },
    { country: "Mozambique", dialCode: "+258", countryCode: "MZ" },
    { country: "Myanmar", dialCode: "+95", countryCode: "MM" },
    { country: "Namibia", dialCode: "+264", countryCode: "NA" },
    { country: "Nauru", dialCode: "+674", countryCode: "NR" },
    { country: "Nepal", dialCode: "+977", countryCode: "NP" },
    { country: "Netherlands", dialCode: "+31", countryCode: "NL" },
    { country: "New Caledonia", dialCode: "+687", countryCode: "NC" },
    { country: "New Zealand", dialCode: "+64 XX XXX XXXX", countryCode: "NZ" },
    { country: "Nicaragua", dialCode: "+505", countryCode: "NI" },
    { country: "Niger", dialCode: "+227", countryCode: "NE" },
    { country: "Nigeria", dialCode: "+234", countryCode: "NG" },
    { country: "Niue", dialCode: "+683", countryCode: "NU" },
    { country: "Norfolk Island", dialCode: "+672", countryCode: "NF" },
    { country: "Northern Mariana Islands", dialCode: "+1670", countryCode: "MP" },
    { country: "Norway", dialCode: "+47", countryCode: "NO" },
    { country: "Oman", dialCode: "+968", countryCode: "OM" },
    { country: "Pakistan", dialCode: "+92", countryCode: "PK" },
    { country: "Palau", dialCode: "+680", countryCode: "PW" },
    { country: "Palestinian Territories", dialCode: "+970", countryCode: "PS" },
    { country: "Panama", dialCode: "+507", countryCode: "PA" },
    { country: "Papua New Guinea", dialCode: "+675", countryCode: "PG" },
    { country: "Paraguay", dialCode: "+595", countryCode: "PY" },
    { country: "Peru", dialCode: "+51", countryCode: "PE" },
    { country: "Philippines", dialCode: "+63", countryCode: "PH" },
    { country: "Pitcairn Islands", dialCode: "+872", countryCode: "PN" },
    { country: "Poland", dialCode: "+48", countryCode: "PL" },
    { country: "Portugal", dialCode: "+351", countryCode: "PT" },
    { country: "Puerto Rico", dialCode: "+1939", countryCode: "PR" },
    { country: "Qatar", dialCode: "+974", countryCode: "QA" },
    { country: "Réunion", dialCode: "+262", countryCode: "RE" },
    { country: "Romania", dialCode: "+40", countryCode: "RO" },
    { country: "Russia", dialCode: "+7", format: "XXX XXX-XX-XX", countryCode: "RU" },
    { country: "Rwanda", dialCode: "+250", countryCode: "RW" },
    { country: "Samoa", dialCode: "+685", countryCode: "WS" },
    { country: "San Marino", dialCode: "+378", countryCode: "SM" },
    { country: "São Tomé & Principe", dialCode: "+239", countryCode: "ST" },
    { country: "Saudi Arabia", dialCode: "+966", countryCode: "SA" },
    { country: "Senegal", dialCode: "+221", countryCode: "SN" },
    { country: "Serbia", dialCode: "+381", countryCode: "RS" },
    { country: "Seychelles", dialCode: "+248", countryCode: "SC" },
    { country: "Sierra Leone", dialCode: "+232", countryCode: "SL" },
    { country: "Singapore", dialCode: "+65", countryCode: "SG" },
    { country: "Sint Maarten", dialCode: "+1", countryCode: "SX" },
    { country: "Slovakia", dialCode: "+421", countryCode: "SK" },
    { country: "Slovenia", dialCode: "+386", countryCode: "SI" },
    { country: "Solomon Islands", dialCode: "+677", countryCode: "SB" },
    { country: "Somalia", dialCode: "+252", countryCode: "SO" },
    { country: "South Africa", dialCode: "+27", countryCode: "ZA" },
    { country: "South Georgia & South Sandwich Islands", dialCode: "+500", countryCode: "GS" },
    { country: "South Korea", dialCode: "+82", countryCode: "KR" },
    { country: "South Sudan", dialCode: "+211", countryCode: "SS" },
    { country: "Spain", dialCode: "+34", format: "XXX XXX XXX", countryCode: "ES" },
    { country: "Sri Lanka", dialCode: "+94", countryCode: "LK" },
    { country: "St. Barthélemy", dialCode: "+590", countryCode: "BL" },
    { country: "St. Helena", dialCode: "+290", countryCode: "SH" },
    { country: "St. Kitts & Nevis", dialCode: "+1869", countryCode: "KN" },
    { country: "St. Lucia", dialCode: "+1758", countryCode: "LC" },
    { country: "St. Martin", dialCode: "+590", countryCode: "MF" },
    { country: "St. Pierre & Miquelon", dialCode: "+508", countryCode: "PM" },
    { country: "St. Vincent & Grenadines", dialCode: "+1784", countryCode: "VC" },
    { country: "Sudan", dialCode: "+249", countryCode: "SD" },
    { country: "Suriname", dialCode: "+597", countryCode: "SR" },
    { country: "Svalbard & Jan Mayen", dialCode: "+47", countryCode: "SJ" },
    { country: "Sweden", dialCode: "+46", countryCode: "SE" },
    { country: "Switzerland", dialCode: "+41", countryCode: "CH" },
    { country: "Taiwan", dialCode: "+886", countryCode: "TW" },
    { country: "Tajikistan", dialCode: "+992", countryCode: "TJ" },
    { country: "Tanzania", dialCode: "+255", countryCode: "TZ" },
    { country: "Thailand", dialCode: "+66", countryCode: "TH" },
    { country: "Timor-Leste", dialCode: "+670", countryCode: "TL" },
    { country: "Togo", dialCode: "+228", countryCode: "TG" },
    { country: "Tokelau", dialCode: "+690", countryCode: "TK" },
    { country: "Tonga", dialCode: "+676", countryCode: "TO" },
    { country: "Trinidad & Tobago", dialCode: "+1868", countryCode: "TT" },
    { country: "Tunisia", dialCode: "+216", countryCode: "TN" },
    { country: "Turkey", dialCode: "+90", countryCode: "TR" },
    { country: "Turkmenistan", dialCode: "+993", countryCode: "TM" },
    { country: "Turks & Caicos Islands", dialCode: "+1649", countryCode: "TC" },
    { country: "Tuvalu", dialCode: "+688", countryCode: "TV" },
    { country: "U.S. Virgin Islands", dialCode: "+1340", countryCode: "VI" },
    { country: "Uganda", dialCode: "+256", countryCode: "UG" },
    { country: "Ukraine", dialCode: "+380", countryCode: "UA" },
    { country: "United Arab Emirates", dialCode: "+971", countryCode: "AE" },
    { country: "United Kingdom", dialCode: "+44", format: "XXXX XXXXX", countryCode: "GB" },
    { country: "United States", dialCode: "+1", format: "(XXX) XXX-XXXX", countryCode: "US" },
    { country: "Uruguay", dialCode: "+598", countryCode: "UY" },
    { country: "Uzbekistan", dialCode: "+998", countryCode: "UZ" },
    { country: "Vanuatu", dialCode: "+678", countryCode: "VU" },
    { country: "Vatican City", dialCode: "+379", countryCode: "VA" },
    { country: "Venezuela", dialCode: "+58", countryCode: "VE" },
    //{ country: "Vietnam", dialCode: "+84", countryCode: "VN" },
    { country: "Wallis & Futuna", dialCode: "+681", countryCode: "WF" },
    { country: "Yemen", dialCode: "+967", countryCode: "YE" },
    { country: "Zambia", dialCode: "+260", countryCode: "ZM" },
    { country: "Zimbabwe", dialCode: "+263", countryCode: "ZW" },
];

export const CountryToPhoneCountryDataMap = PhoneCountryDataList.reduce(
    (acc, countryDialCode) => ({
        ...acc,
        [countryDialCode.country]: countryDialCode,
    }),
    {} as Record<string, PhoneCountryData>,
);

export const CountryCodeToPhoneCountryDataMap = PhoneCountryDataList.reduce(
    (acc, countryDialCode) => ({
        ...acc,
        [countryDialCode.countryCode]: countryDialCode,
    }),
    {} as Record<string, PhoneCountryData>,
);

export const CountryDialCodeToPhoneCountryDataMap = PhoneCountryDataList.reduce(
    (acc, data) => ({
        ...acc,
        [data.dialCode]: data,
    }),
    {} as Record<string, PhoneCountryData>,
);
