export const removeNonAlphaNumerics = (str: string) => str.replace(/[^a-zA-Z0-9]/g, "");

export const withCommas = (x: string, usePeriod = false) => x.replace(/\B(?=(\d{3})+(?!\d))/g, usePeriod ? "." : ",");

export const uppercaseFirstLetter = (str: string) =>
    `${str.charAt(0).toLocaleUpperCase()}${str.slice(1).toLocaleLowerCase()}`;

export const editDistance = (str1: string, str2: string) => {
    const track = Array(str2.length + 1)
        .fill(null)
        .map(() => Array(str1.length + 1).fill(null));

    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }

    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }

    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator, // substitution
            );
        }
    }
    return track[str2.length][str1.length];
};

const alphaNumericCharactersUpperOnly = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

export const randomAlphaNumericWithLength = (length: number) => {
    let result = "";
    for (let i = 0; i < length; i++) {
        result += alphaNumericCharactersUpperOnly.charAt(
            Math.floor(Math.random() * alphaNumericCharactersUpperOnly.length),
        );
    }

    return result;
};

export const formatSlug = (str: string) => {
    const parsedCharacters = str
        .replace(/[^0-9a-zA-Z\- ]/gi, "")
        .replace(" ", "-")
        .toLocaleLowerCase();

    const split = parsedCharacters.split("-");

    return split.reduce((acc, val) => {
        if (acc === "") {
            return val;
        }

        if (acc.endsWith("-") && val === "") {
            return acc;
        }

        return `${acc}-${val}`;
    }, "");
};

export const capitalize = (text: string) => `${(text[0] ?? "").toUpperCase()}${text.slice(1)}`;

export const oxfordCommaList = (words: string[]) => {
    if (words.length === 0) {
        return "";
    }

    if (words.length === 1) {
        return words[0];
    }

    if (words.length === 2) {
        return `${words[0]} and ${words[1]}`;
    }

    return `${words.slice(0, words.length - 1).join(", ")}, and ${words[words.length - 1]}`;
};

export const truncateTextWithoutBreakingWords = (text: string, characterLimit: number) => {
    const truncated = text.slice(0, characterLimit);

    const truncatedSplit = truncated.split(" ");
    const textSplit = text.split(" ");

    if (truncatedSplit[truncatedSplit.length - 1] === textSplit[truncatedSplit.length - 1]) {
        return truncated;
    } else {
        return truncatedSplit.slice(0, truncatedSplit.length - 1).join(" ");
    }
};

export const pluralS = (num: number) => (num === 1 ? "" : "s");
