import parsePhoneNumber from "libphonenumber-js";

import { CountryDialCodeToPhoneCountryDataMap, PhoneCountryData } from "./country";

export const cymbalParsePhoneNumber = (phoneNumber: string) => {
    let sliceEnd = 2;
    while (sliceEnd < 5) {
        if (CountryDialCodeToPhoneCountryDataMap[phoneNumber.slice(0, sliceEnd)]) {
            break;
        }
        sliceEnd++;
    }

    return {
        countryData: CountryDialCodeToPhoneCountryDataMap[phoneNumber.slice(0, sliceEnd)],
        phone: phoneNumber.slice(sliceEnd, phoneNumber.length),
    };
};

export const getFormattedPhoneNumber = (phoneNumber: string, phoneCountryData: PhoneCountryData) => {
    if (!phoneCountryData.format) {
        return `${phoneCountryData.dialCode} ${phoneNumber}`;
    }

    const numbers = phoneNumber.split("");

    let formattedPhoneNumber = phoneCountryData.format;
    for (const number of numbers) {
        if (formattedPhoneNumber.indexOf("X") === -1) {
            return `${phoneCountryData.dialCode} ${phoneNumber}`;
        }
        formattedPhoneNumber = formattedPhoneNumber.replace("X", number);
    }

    if (formattedPhoneNumber.indexOf("X") !== -1) {
        return `${phoneCountryData.dialCode} ${phoneNumber}`;
    }

    return `${phoneCountryData.dialCode} ${formattedPhoneNumber}`;
};

export const tryFormatPhone = (phone: string) => {
    const parsedPhone = parsePhoneNumber(phone);
    if (!parsedPhone) {
        return phone;
    }

    return parsedPhone.formatInternational();
};
